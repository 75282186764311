exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-intermediate-js": () => import("./../../../src/pages/intermediate.js" /* webpackChunkName: "component---src-pages-intermediate-js" */),
  "component---src-pages-landing-js": () => import("./../../../src/pages/landing.js" /* webpackChunkName: "component---src-pages-landing-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-templates-about-us-js": () => import("./../../../src/templates/about-us.js" /* webpackChunkName: "component---src-templates-about-us-js" */),
  "component---src-templates-article-amp-js": () => import("./../../../src/templates/article.amp.js" /* webpackChunkName: "component---src-templates-article-amp-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-author-page-js": () => import("./../../../src/templates/authorPage.js" /* webpackChunkName: "component---src-templates-author-page-js" */),
  "component---src-templates-category-amp-js": () => import("./../../../src/templates/category.amp.js" /* webpackChunkName: "component---src-templates-category-amp-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-index-amp-js": () => import("./../../../src/templates/index.amp.js" /* webpackChunkName: "component---src-templates-index-amp-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-pages-js": () => import("./../../../src/templates/pages.js" /* webpackChunkName: "component---src-templates-pages-js" */),
  "component---src-templates-paginated-article-amp-js": () => import("./../../../src/templates/paginatedArticle.amp.js" /* webpackChunkName: "component---src-templates-paginated-article-amp-js" */),
  "component---src-templates-paginated-article-js": () => import("./../../../src/templates/paginatedArticle.js" /* webpackChunkName: "component---src-templates-paginated-article-js" */),
  "component---src-templates-sub-category-amp-js": () => import("./../../../src/templates/subCategory.amp.js" /* webpackChunkName: "component---src-templates-sub-category-amp-js" */),
  "component---src-templates-sub-category-js": () => import("./../../../src/templates/subCategory.js" /* webpackChunkName: "component---src-templates-sub-category-js" */)
}

